import React, { useEffect, useState, lazy, Suspense } from "react";
import { Helmet } from "react-helmet";
import "./App.css";
import "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Routes, useLocation } from "react-router-dom";
import HeaderInfo from "./Components/Header-Info/HeaderInfo";
import ModernNavbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import Loader from "./Components/LazyLoading/Loader"; // Adjust path as necessary
import Support from "./Components/support/Support";
import NotFound from "./Components/NotFound/NotFound";

const Home = lazy(() => import("./Pages/Home/Home"));
const ContactUs = lazy(() => import("./Pages/Contact/ContactUs"));
const Courses = lazy(() => import("./Pages/Courses/Courses"));
const BlogPage = lazy(() => import("./Pages/BLogPage/BlogPage"));
const AboutUs = lazy(() => import("./Pages/AboutUs/AboutUs"));
const Detail = lazy(() => import("./Components/Detail/Detail"));
const Dashboard = lazy(() => import("./Components/Dashboard/Dashboard"));
const BlogDetail = lazy(() => import("./Components/Detail/BlogDetail"));
const ProjectDetail = lazy(() => import("./Components/Detail/ProjectDetail"));
const Services = lazy(() => import("./Pages/Services/Services"));

function App() {
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    const timer = setTimeout(() => {
      setLoading(false);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const MainContent = () => {
    const isAdminRoute = location.pathname.startsWith("/admin");

    return (
      <>
        <ModernNavbar />
        {!isAdminRoute && <Support />}
        <Routes>
          <Route path="/admin" element={<Dashboard />} />
          <Route path="/" element={<Home />} />
          <Route path="/Courses" element={<Courses />} />
          <Route path="/Blog" element={<BlogPage />} />
          <Route path="/Service" element={<Services />} />
          <Route path="/About" element={<AboutUs />} />
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="/Detail/:courseId" element={<Detail />} />
          <Route path="/BlogDetail/:blogId" element={<BlogDetail />} />
          <Route path="/ProjectDetail/:projectId" element={<ProjectDetail />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        {!isAdminRoute && <Footer />}
      </>
    );
  };

  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Welcome to BZSoftech, where we empower tomorrow’s innovators with
          cutting-edge technology solutions. Our comprehensive technical courses
          and software development services are designed to propel your business
          forward. Explore our offerings and discover how we can help you
          achieve your goals.
        </title>
        <meta
          name="description"
          content="BZSoftech provides comprehensive technical courses training and innovative software development solutions."
        />
        <link rel="canonical" href="https://www.bzsoftech.com" />
      </Helmet>
      <HeaderInfo />
      {loading ? (
        <Loader />
      ) : (
        <Suspense fallback={<Loader />}>
          <MainContent />
        </Suspense>
      )}
    </div>
  );
}

export default App;
